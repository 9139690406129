.leftContainer{
    min-height: 100%;
    position: relative;
}
.headerContainer, .leftContainer {
    background-color: #f9f9f9 !important;
    max-width: 480px;
    width: 100%;
   
}
.rightContainer {
    background: url(../../../public/Images/global-bg-rays.svg);
    background-color: #fff;
    background-size: cover;
    overflow: hidden;
    right: 0;
    z-index: 4;
}
.rightContainer {
    bottom: 0;
    left: 480px;
    position: fixed;
    top: 0;
    border-left: 10px solid #e0e0e0;
}
#root{
    height: 100vh;
}

.bet-container {
    width: 100%;
    display: flex;
    justify-content: center;
    .input-container {
        width: 75%;
        display: flex;
        align-items: center;
        input {
            height: 40px;
            border: 1.5px solid black;
            border-radius: 0;
            width: 60%;        
        }
        button {
            background-color: #0dcaf0 !important;
            height: 40px;
            border-radius: 0 2px 2px 0;
            border: 1.5px solid black;
            border-left: none;
            margin-left: 0px !important;
        }
    }
    .input-button {
        display: inline-block;
        
    }
}

.card .card-header {
    padding: 10px !important;
}

.fw-bold {
    font-weight: 900;
}

.bet-card-container.my-2.card .card-body {
    padding: 10px 15px;
}

.bet-card-container {
    .spinner-border {
        display: inline-block;
        width: 2rem;
        height: 2rem;
        vertical-align: text-bottom;
        border: .25em solid;
        border-right: .25em solid transparent;
        border-radius: 50%;
        -webkit-animation: spinner-border .75s linear infinite;
        animation: spinner-border .75s linear infinite;
    }
    .finding-player-text {
        margin-left: 10px;
    }

    .btn-group-sm>.btn, .btn-sm {
        padding: .25rem .5rem;
        font-size: .875rem;
        line-height: 1.5;
        border-radius: .2rem;
    }

    .btn-danger {
        color: #fff !important;
        background-color: #dc3545;
        border-color: #dc3545;
    }

    .btn-sm {
        color: #fff !important;
    }

    .btn-start {
        background-color: rgb(0, 123, 255);
        border-color: rgb(0, 123, 255);
        margin-right: 5px !important;
    }
}