/* *{
    font-family: 'Roboto', sans-serif !important;
} */

.row {
    margin-right: -15px !important;
    margin-left: -15px !important;
}

p {
    margin-top: 0 !important;
    margin-bottom: 1rem !important;
}

button,
input {
    overflow: visible !important;
}

button,
input,
optgroup,
select,
textarea {
    margin: 0 !important;
    font-family: inherit !important;
    font-size: inherit !important;
    line-height: inherit !important;
}

.search-slt {
    display: block !important;
    width: 100% !important;
    font-size: 0.875rem !important;
    line-height: 1.5 !important;
    background-color: #fff !important;
    background-image: none !important;
    border: 1px solid #ccc !important;
    height: calc(2rem + 2px) !important;
    border-radius: 0 !important;
}

option {
    font-weight: normal !important;
    display: block !important;
    white-space: nowrap !important;
    min-height: 1.2em !important;
    padding: 0px 2px 1px !important;
}

.btn:not(:disabled):not(.disabled) {
    cursor: pointer !important;
}

.wrn-btn {
    width: 100% !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    text-transform: capitalize !important;
    height: calc(2rem + 2px) !important;
    border-radius: 0 !important;
}

.btn-info {
    color: #fff !important;
    background-color: #17a2b8 !important;
    border-color: #17a2b8 !important;
}

.text-info {
    color: #17a2b8 !important;
}

.h4,
h4 {
    font-size: 1.49rem;
    font-weight: 500;
    line-height: 1.2;
}

.small,
small {
    font-size: 80% !important;
    font-weight: 400 !important;
}

b,
strong {
    font-weight: bolder !important;
}

a {
    color: #49b5e7 !important;
}

.far {
    font-weight: 400;
    font-family: "FontAwesome";
}

.btn {
    display: inline-block !important;
    font-weight: 400 !important;
    color: #212529 !important;
    text-align: center !important;
    vertical-align: middle !important;
    user-select: none !important;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem !important;
    font-size: 0.80rem !important;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out !important;
}

.ml-auto,
.mx-auto {
    margin-left: auto !important;
}

.mr-auto,
.mx-auto {
    margin-right: auto !important;
}

.pl-3,
.px-3 {
    padding-left: 1rem !important;
}

.pr-3,
.px-3 {
    padding-right: 1rem !important;
}

.fa-question-circle:before {
    content: "\f059";
    font-family: "FontAwesome";
    font-style: normal;
}

.fa-play-circle:before {
    content: "\f144";
    font-family: "FontAwesome";
    font-style: normal;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Dosis', sans-serif;
    font-weight: 600;
}


.wallet {
    height: 70px;
    mix-blend-mode: normal;
    opacity: 1;
    /* background:#fff; */
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    width: 100%;
}

.refer-earning-container {
    padding-top: 60px !important;
    border: 1px solid rgba(0, 0, 0, .125) !important;
    border-radius: .25rem !important;

    .text-dark {
        color: #343a40 !important;
    }

    .bg-light {
        background-color: #f8f9fa !important;
    }

    b,
    span {
        font-family: 'Roboto', sans-serif;
        -webkit-font-smoothing: antialiased;
        -webkit-tap-highlight-color: transparent;
        text-decoration: none;
    }

    .card-body {
        padding: 1.25rem !important;
    }
}

.refer-code-container {
    border: 1px solid rgba(0, 0, 0, .125) !important;
    border-radius: .25rem !important;

    .text-dark {
        color: #343a40 !important;
    }

    .bg-light {
        background-color: #f8f9fa !important;
    }

    b,
    span,
    p {
        font-family: 'Roboto', sans-serif;
        -webkit-font-smoothing: antialiased;
        -webkit-tap-highlight-color: transparent;
        text-decoration: none;
        font-size: 1rem !important;
    }

    .card-body {
        padding: 1.25rem !important;
    }

    .form-control:disabled,
    .form-control[readonly] {
        background-color: #e9ecef;
        opacity: 1;
    }

    .p-2 {
        padding: .5rem !important;
    }

    .form-control {
        display: block;
        width: 100%;
        height: calc(1.5em + .75rem + 2px);
        padding: .375rem .75rem;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #495057;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #ced4da;
        border-radius: .25rem;
        transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    }

    .copy-button-group {
        .btn {
            display: inline-block !important;
            font-weight: 400 !important;
            color: #212529 !important;
            text-align: center !important;
            vertical-align: middle !important;
            -webkit-user-select: none !important;
            -ms-user-select: none !important;
            user-select: none !important;
            background-color: transparent !important;
            border: 1px solid transparent !important;
            padding: .375rem .75rem !important;
            font-size: 1rem !important;
            line-height: 1.5 !important;
            border-radius: .25rem !important;
            transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out !important;
        }

        .btn-primary {
            color: #fff !important;
            background-color: #007bff !important;
            border-color: #007bff !important;
        }
    }

    h5 {
        font-size: 1.25rem;
        font-family: 'Roboto', sans-serif;
        -webkit-font-smoothing: antialiased;
        -webkit-tap-highlight-color: transparent;
        text-decoration: none;
        margin-bottom: .5rem;
        font-weight: 500;
        line-height: 1.2;
    }

    .d-grid {

        .btn {
            display: inline-block !important;
            font-weight: 400 !important;
            color: #212529 !important;
            text-align: center !important;
            vertical-align: middle !important;
            -webkit-user-select: none !important;
            -ms-user-select: none !important;
            user-select: none !important;
            background-color: transparent !important;
            border: 1px solid transparent !important;
            padding: .375rem .75rem !important;
            font-size: 1rem !important;
            line-height: 1.5 !important;
            border-radius: .25rem !important;
            transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out !important;

            .ms-2 {
                margin-left: 0.3rem;
            }

            svg {
                height: 16px;
                width: 16px;
            }
        }

        .btn-success {
            color: #fff !important;
            background-color: #28a745 !important;
            border-color: #28a745 !important;
            font-size: 1rem !important;
        }

        .btn-secondary {
            color: #fff !important;
            background-color: #6c757d !important;
            border-color: #6c757d !important;
        }
    }
}

.divider-x {
    background: #f1f1f1;
    width: 100%;
    height: 10px;
}

.how_works_container {
    border: 1px solid rgba(0, 0, 0, .125) !important;
    border-radius: .25rem !important;

    .text-dark {
        color: #0d0d0d !important;
    }

    .bg-light {
        background-color: #ffffff !important;
    }

    b,
    span,
    p,
    li {
        font-family: 'Roboto', sans-serif;
        -webkit-font-smoothing: antialiased;
        -webkit-tap-highlight-color: transparent;
        text-decoration: none;
        font-size: 1rem !important;
    }

    .list-group-item {
        font-weight: unset !important;
    }
}

.support-container {
    .cxy {
        align-items: center;
        display: flex;
        justify-content: center;
    }

    .games-section-title {
        color: #01002e;
        font-weight: 700;
    }

    .btn {
        display: inline-block !important;
        font-weight: 400 !important;
        color: #212529 !important;
        text-align: center !important;
        vertical-align: middle !important;
        -webkit-user-select: none !important;
        -ms-user-select: none !important;
        user-select: none !important;
        background-color: transparent !important;
        border: 1px solid transparent !important;
        padding: .375rem .75rem !important;
        font-size: 1rem !important;
        line-height: 1.5 !important;
        border-radius: .25rem !important;
        transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out !important;
    }

    .btn-success {
        color: #fff !important;
        background-color: #28a745 !important;
        border-color: #28a745 !important;
    }

    .btn-dark {
        color: #fff !important;
        background-color: #343a40 !important;
        border-color: #343a40 !important;
    }

    .btn-group-lg>.btn,
    .btn-lg {
        padding: .5rem 1rem !important;
        font-size: 1.25rem !important;
        line-height: 1.5 !important;
        border-radius: .3rem !important;
    }

    svg {
        height: 20px;
        width: 20px;
    }

    .me-2 {
        margin-right: 0.3rem;
    }
}

.transaction-history-container {
    .MuiPagination {
        margin: 0;
        display: flex;
        padding: 0;
        flex-wrap: wrap;
        list-style: none;
        align-items: center;
    }

    li {
        display: list-item;
        text-align: -webkit-match-parent;
        unicode-bidi: isolate;
    }

    .MuiPagination li a {
        color: rgba(0, 0, 0, 0.87) !important;
        height: 32px;
        margin: 0 3px;
        padding: 0 6px;
        font-size: 0.875rem;
        min-width: 32px;
        box-sizing: border-box;
        text-align: center;
        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
        font-weight: 400;
        line-height: 1.43;
        border-radius: 16px;
        letter-spacing: 0.01071em;
        display: inline-block;
        line-height: 32px;
    }

    .fa {
        display: inline-block;
        font: normal normal normal 14px / 1 FontAwesome;
        font-size: inherit;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: rgba(0, 0, 0, 0.87) !important;
        background-color: unset !important;
        /* font-size: 16px !important; */
        font-weight: bolder !important;
        font-size: 11.6667px !important;
        line-height: 11.6667px;
    }

    .MuiPagination li a.active {
        color: #fff !important;
        background-color: #f50057;
    }

    .container {
        display: grid;
        height: auto;
        place-items: center;
        width: 100dvw;
    }

    .rounded-pill {
        border-radius: 50rem !important;
    }

    .bg-primary {
        background-color: rgba(13, 110, 253, 1) !important;
        font-family: 'Roboto', sans-serif;
        -webkit-font-smoothing: antialiased;
        -webkit-tap-highlight-color: transparent;
        text-decoration: none;
    }

    .text-white {
        color: rgba(255, 255, 255, 1) !important;
    }

    .border {
        border: 1px solid #dee2e6 !important;
    }

    .text-start {
        text-align: left !important;
        font-family: 'Roboto', sans-serif;
        -webkit-font-smoothing: antialiased;
        -webkit-tap-highlight-color: transparent;
        text-decoration: none;
        font-size: 12.8px;
    }

    span {
        font-family: 'Roboto', sans-serif;
        -webkit-font-smoothing: antialiased;
        -webkit-tap-highlight-color: transparent;
        text-decoration: none;
    }

    .border-bottom {
        border-bottom: 1px solid #dee2e6 !important;
    }

    .border-end {
        border-right: 1px solid #dee2e6 !important;
    }

    .text-success {
        --bs-text-opacity: 1;
        color: rgba(25, 135, 84, 1) !important;
    }

    .text-danger {
        --bs-text-opacity: 1;
        color: rgba(220, 53, 69, 1) !important;
    }

    .bg-success {
        background-color: rgba(25, 135, 84, 1) !important;
        color: rgba(255, 255, 255, 1) !important;
        font-family: 'Roboto', sans-serif;
        -webkit-font-smoothing: antialiased;
        -webkit-tap-highlight-color: transparent;
        text-decoration: none;
    }
}

.view-game-container {
    .divider-x {
        background: #f1f1f1;
        width: 100%;
        height: 10px;
    }

    .card {
        word-wrap: break-word;
        background-clip: initial;
        background-color: #fff;
        border-radius: 0.375rem;
        display: flex;
        flex-direction: column;
        min-width: 0;
        position: relative;
    }

    span,
    p,
    button {
        font-family: "Roboto";
        -webkit-font-smoothing: antialiased;
        -webkit-tap-highlight-color: transparent;
    }

    .shadow {
        box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15) !important;
    }

    .card-body {
        flex: 1 1 auto;
        min-height: 1px;
        padding: 1.25rem !important;
        font-family: 'Roboto', sans-serif;
        -webkit-font-smoothing: antialiased;
        -webkit-tap-highlight-color: transparent;
        text-decoration: none;
    }

    .text-success {
        color: #28a745 !important;
    }

    .room-code {
        background: #fff;
        padding: 20px 20px;
        border: solid 4px #eee;
        border-left: none;
        border-right: none;
    }

    .room-code-bg.text-center {
        background: #f0f0f0;
        padding: 20px;
        border-radius: 10px;
    }

    .room-code-bg.text-center {
        background: transparent;
    }

    .text-danger {
        color: #dc3545 !important;
    }

    .btn {
        display: inline-block !important;
        font-weight: 400 !important;
        color: #212529 !important;
        text-align: center !important;
        vertical-align: middle !important;
        -webkit-user-select: none !important;
        user-select: none !important;
        background-color: transparent;
        border: 1px solid transparent;
        padding: 0.375rem 0.75rem !important;
        font-size: 1rem !important;
        line-height: 1.5;
        border-radius: 0.25rem;
        transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    }

    .btn-primary {
        color: #fff !important;
        background-color: #007bff;
        border-color: #007bff;
    }

    .match-status {
        padding: 0;
    }

    .match-status .title {
        background: #f4e6e6;
        padding: 10px 20px;
        margin: 0;
    }

    h6.title {
        display: block;
        font-size: 1rem;
        margin-block-start: 2.33em;
        margin-block-end: 2.33em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        font-weight: 500;
        unicode-bidi: isolate;
    }

    h6,
    div {
        font-family: 'Roboto', sans-serif;
        -webkit-font-smoothing: antialiased;
        -webkit-tap-highlight-color: transparent;
        text-decoration: none;
    }

    h6 {
        font-size: 1rem;
        line-height: 1.2;
    }

    .spinner-border {
        display: inline-block;
        width: 2rem;
        height: 2rem;
        vertical-align: text-bottom;
        border: .25em solid;
        border-right: .25em solid transparent;
        border-radius: 50%;
        -webkit-animation: spinner-border .75s linear infinite;
        animation: spinner-border .75s linear infinite;
    }

    .radio-btn {
        padding: 0 20px 20px;
    }

    .btn-success {
        color: #fff !important;
        background-color: #28a745;
        border-color: #28a745;
    }

    .btn-danger {
        color: #fff !important;
        background-color: #dc3545;
        border-color: #dc3545;
    }

    .btn-secondary {
        color: #fff !important;
        background-color: #6c757d;
        border-color: #6c757d;
    }

    .card-header {
        padding: .75rem 1.25rem;
        margin-bottom: 0;
        background-color: rgba(0, 0, 0, .03);
        border-bottom: 1px solid rgba(0, 0, 0, .125);

        span {
            font-family: 'Roboto', sans-serif;
            -webkit-font-smoothing: antialiased;
            -webkit-tap-highlight-color: transparent;
            text-decoration: none;
        }

        .list-group-item {
            font-weight: unset;

            h6 {
                font-weight: 500;
                
            }
        }
    }
}